

export async function injectContent(content)
{
	const assets = BX.processHTML(content);

	BX.loadCSS(assets.STYLE);
	if (assets.SCRIPT)
	{
		await new Promise(resolve => {
			BX.ajax.processScripts(assets.SCRIPT, false, resolve)
		});
	}

	return true;
}

export async function injectAssets(assets)
{
	await new Promise((resolve, reject) => {
		BX.load(assets.css || [], () => {
			BX.loadScript(assets.js || [], resolve);
		});
	});

	const stringAsset = (assets.string || []).join('\n');
	await BX.html(document.head, stringAsset, { useAdjacentHTML: true });

	return true;
}