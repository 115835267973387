const SPINNER_TYPES = ['border', 'grow'];
const SPINNER_SYZES = ['sm'];

export default class Spinner
{
	constructor(options = {})
	{
		this.options = {
			target: null,
			size: 'normal',
			type: 'border',
			overlay: true,
			cssClasses: null,
			zIndexOptions: null,
			...options
		};

		if (!SPINNER_TYPES.includes(this.options.type))
			throw new Error(`The spinner type is incorrect`);

		this.spinner = null;
	}

	setOptions(options = {})
	{
		this.options = {
			...this.options,
			...options
		};
	}

	async start()
	{
		if (!this.spinner)
			await this.create();

		this.spinner.show();
	}

	end()
	{
		if (!this.spinner)
			return;

		this.spinner.close();
		this.remove();
	}

	async create()
	{
		const { target, type, size, cssClasses, overlay, zIndexOptions } = this.options;

		const content = BX.Tag.render`
			<div class="spinner-${type}" role="status">
				<span class="visually-hidden">Loading...</span>
			</div>`;

		content.classList.add(cssClasses);

		if (SPINNER_SYZES.includes(size))
			content.classList.add(`spinner-${type}-${size}`);

		return BX.Runtime.loadExtension('main.popup')
			.then(() => {
				this.spinner = BX.PopupWindowManager.create({
					id: `spinner_${ target?.id || BX.Text.getRandom(5) }`,
					content,
					overlay,
					darkMode: true,
					zIndexOptions,
					targetContainer: target,
					bindOptions: {
						forcedBindPosition: false,
						forceLeft: false,
						forceTop: false,
					},
				});
			});
	}

	remove()
	{
		this.spinner.destroy();
		this.spinner = null;
	}
}