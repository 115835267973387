import { injectContent, injectAssets } from './injector';

export async function load(url, method = 'GET', data = {}, preparePost = true)
{
	const requestData = await new Promise((resolve, reject) => {
		BX.ajax({
			url,
			method,
			data,
			preparePost,
			onsuccess: resolve,
			onfailure: reject
		});
	});

	const jsonData = BX.parseJSON(requestData);
	if (jsonData)
	{
		if (
			jsonData.status === 'success'
			&& jsonData.data.assets
		)
		{
			await injectAssets(jsonData.data.assets);
		}
	}

	return jsonData?.data || requestData;
}

export async function insertToNode(node, url, method = 'GET', data = {}, preparePost = true)
{
	const requestData = await load(url, method, data, preparePost);
	node.innerHTML = requestData.html;

	await injectContent(requestData.html);

	return requestData;
}